// 个人中心 // 播放历史
<template>
  <div id="app3">
    <!-- 顶部 -->
    <Top :white="$store.state.model.black" ref="top"></Top>

    <div class="history_body">
      <!-- 左侧 -->
      <Leftside></Leftside>
      <transition name="RightToleft">
        <!-- 右侧主题 -->
        <div class="his_r_box" v-show="enter">
          <div class="history_body_right">
            <div class="his_r_title">
              <span>帮助中心</span>
              <div class="help_cust" @click="clilianxi">
                <img src="../assets/img/happy.png" alt="联系客服图标" />
                <a>联系客服</a>
              </div>
            </div>
            <!-- 主体 -->
            <div class="his_r_body">
              <div class="help_body">
                <!-- 问题标签头 -->
                <div class="help_tag">
                  <div
                    class="help_list"
                    :class="{ help_list_act: listIndex == index }"
                    v-for="(item, index) in faq_category_list"
                    :key="index"
                    @click="helptagChange($event, index, item.id)"
                  >
                    <a>{{ item.name }}</a>
                  </div>
                </div>
                <!-- 问题主体 -->
                <div class="help_body">
                  <div class="ques_list" v-for="(item, index) in faq_list" :key="index">
                    <div class="ques_title">
                      <img src="../assets/img/help_ques.png" alt="问题的图标" />
                      <a>{{ item.title }}</a>
                    </div>
                    <div class="ques_nodes">
                      <p>{{ item.content }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>

    <!-- WIN 联系客服弹窗 -->
    <transition name="show">
      <div class="vipcardWIN" v-show="$store.state.showlogin.custReg">
        <img
          class="vipcardlogo"
          src="../assets/img/winlogo.png"
          height="47"
          width="123"
          alt="logo图标"
        />
        <img
          class="kefugo_img"
          v-if="customer_service_list.wechat_qrcode"
          :src="customer_service_list.wechat_qrcode"
          width="200"
          height="200"
          alt="联系方式的二维码"
        />
        <span class="b_g_11">打开微信扫一扫添加</span>
        <span class="b_g_22">在线时间：{{ timeText }}</span>
        <span class="b_g_22">{{ timeArea }}</span>

        <img
          class="login-close"
          @click="YS.gotoVip($event, 'close', 'custReg')"
          src="../assets/img/close.png"
          alt="关闭联系客服弹窗"
        />
      </div>
    </transition>
    <!-- 遮罩层 -->
    <div id="cover"></div>
  </div>
</template>
<script>
let that;
let fetch;
import Top from '../components/Top'; //导航top
import Leftside from '../components/Leftside'; //左侧主题
export default {
  components: {
    Top,
    Leftside,
  },
  name: 'History',
  data() {
    return {
      timeText: '', //客服在线时间
      timeArea: '', //客服时间时区
      listIndex: 0, //问题选择下标
      customer_service_list: [], //客服信息
      symbol: '', //客服信息索引
      faq_list: [], // 帮助中心列表
      faq_category_list: [], // 分类列表
      enter: false,
      ques: [{ name: '注册登录' }, { name: '充值到账' }, { name: '金币任务' }],
    };
  },
  async created() {
    that = this;
    fetch = this.api.user;
    //var ismob = /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent);
    that.YS.toMobile('/help');
    let data = await fetch.api_category({});
    try {
      if (data && data.faq_category_list) {
        that.faq_category_list = data.faq_category_list;
        let data1 = await fetch.api_categoryid({
          category_id: data.faq_category_list[0].id,
        });
        try {
          if (data && data.faq_category_list && data.faq_category_list[0]) {
            that.faq_list = data1.faq_list;
          }
        } catch (error) {
          console.log(error.message);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  },
  beforeMount() {},
  mounted() {
    that.enter = true;
    that.YS.toTop2();
    // that.helptagChange(1, 0); //默认选中第0个样式
    that.YS.closeallWin(); //关闭所有弹窗
    that.YS.actlist(that.$store.state.leftListcss, 4, 'history_lc_list_act'); //添加默认选中样式
    that.YS.iconAct(that.$store.state.leftComponent, 4); //添加默认选中样式
    if (that.$route.query.kefu) {
      that.symbol = that.$route.query.kefu;
      that.customerService();
      that.YS.gotoVip(1, 'open', 'custReg'); //打开联系客服
    }
    if (that.$refs.top) {
      that.timeText = that.$refs.top.timeText;
      that.timeArea = that.$refs.top.timeArea;
    }
  },
  methods: {
    helptagChange(e, i, id) {
      fetch
        .api_categoryid({
          category_id: id,
        })
        .then((data) => {
          // var obj = document.getElementsByClassName('help_list');
          // that.YS.actlist(obj, i, 'help_list_act');
          that.faq_list = data.faq_list;
          that.listIndex = i;
        });
    },
    //点击联系客服
    clilianxi() {
      //替换帮助中心的标识
      that.symbol = 'faq';
      that.customerService();
      that.YS.gotoVip(1, 'open', 'custReg');
    },
    //获取客服信息
    customerService() {
      fetch.api_customerServiceinfo({ symbol: that.symbol }).then((data) => {
        that.customer_service_list = data.customer_service_list;
      });
    },
  },
};
</script>
<style lang="scss" scope></style>
